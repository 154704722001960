.user_component{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .user-circle-image-container{
        width: 70px;
        height: 70px;
        border-radius: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .user_component_title{
        font-weight: 700;
    }

    .user_component_subtitle{
        font-weight: 400;
    }
}