#content{
    height: auto;
    flex-grow: 1;

    &>.container{
        height: 100%;
    }
}

.search_with_groups_component{
    height: 100%;

    &>.container{
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        
        #scrollable_container{
            flex-grow: 1;
            // max-height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            position: relative;

            .none_item {
                width: 1px;
                height: 101%;
                position: absolute;
            }

            .infinite-scroll-component {
                // height: 100.10%;
                overflow-x: hidden !important;
            }
        }
    }
}

.save_filter_button{
    background-color: #233746;
    padding: 2px 10px;
    color: white;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-right: 10px;
}

.delete_modal_delete{
    background: var(--color-red-soft);
    color: white;
    border: 1px solid var(--color-red-soft);
    border-radius: 4px;
    margin-top: 10px;
}

.delete_modal_content_input{
    width: 90%;
    margin-left: 5%;
    border: 1px solid var(--color-red-soft);
    border-radius: 4px;
    padding: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
}