.edit_attendance_page {
    .page_content {
        min-height: 70vh;

        .card_event_schedule_component,
        .card_tracking_interaction_component {
            .billable_container {
                display: none !important;
            }
        }

        .container {
            .wrapper_horizontal {
                width: 100%;
                overflow-x: auto;

                .content_tasks {
                    min-width: 1000px;

                    .line_middle {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        margin-bottom: 1em;

                        &::before {
                            content: "";
                            z-index: 0;
                            position: absolute;
                            top: calc(50% - 0.5px);
                            width: calc(100% - 3em);
                            height: 1px;
                            background-color: #ccc;
                            margin-right: 1em;
                        }

                        .box_white {
                            background-color: #fbfbfb;
                            z-index: 1;
                            padding: 0.4em 1em;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }
}