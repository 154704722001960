.container_roles{
    display: flex;
    overflow: auto;
    height: 100%;
    margin-top: 0px;
    .container_roles_cards{
        width: auto;
        margin-right: 10px;
    }
}


.container{
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        
        #scrollable_container_roles{
            flex-grow: 1;
            overflow-y: auto;
            overflow-x: auto;
            position: relative;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 5px;

        }
    }

.no_data_retrieved{
    margin-top: 100px;
    text-align: center;
    color: rgb(243, 107, 107);
}

@media (max-width: 768px) {
    .container{
        
        #scrollable_container_roles{
            flex-grow: 1;
            overflow-y: auto;
            overflow-x: hidden;
            position: relative;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 5px;

        }
    }
}
