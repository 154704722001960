.drop_down_component {
    position: relative;

    .btn_drop {
        display: flex;
        height: fit-content;
        border-radius: 5px;
        font-size: 14px;




        .icon_container {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            background-color: #ffffff24;
            border-radius: 5px 0 0 5px;
            padding: 0.5em;

            .icon {
                width: 1.2em;
                height: 1.2em;
                color: currentColor;
            }
        }

        .text {
            display: flex;
            align-items: center;
            flex-grow: 1;
            justify-content: space-between;

            .container_text {
                padding: 0.5em 1em 0.5em 0em;
            }

            .color_line {
                width: 5px;
                height: calc(100% - 0.2em);
                border-radius: 0 5px 5px 0;
                margin: 0.1em;
            }
        }

        &.main {
            background-color: var(--color-blue);
            color: white;
        }

        &.disabled {
            background-color: var(--color-gray-dark);
            color: white;
        }
    }

    .drop_down_container {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 1;

        .autocomplete_component {
            z-index: 100;
        }
    }


}