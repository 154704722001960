.card-role-component-edit {
    .card-body-edit {
        background-color: white;
        padding: 1em;
        border-radius: 5px;

        .role-title-detail{
            font-weight: 700;
            margin-bottom: 20px;
        }
        .role-description-detail{
            text-align: justify;
        }
    }
}