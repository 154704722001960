.container{

.permissions-info{
    height: 100%;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;

    .permissions-search-bar{
        width: 100%;
        border: 1px solid rgb(194, 194, 194);
        border-radius: 6px;
        margin-top: 5px;
        margin-bottom: 20px;
        padding: 6px 10px 6px 10px;
    }

    .permissions-search-bar:focus {
        border-color: var(--color-blue);
        outline: none; 
    }

    #permissions-info-container{
        flex-grow: 1;
        overflow-y: auto;
        overflow-x: auto;
        position: relative;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 5px;
    
        .module-info-title{
            margin-bottom: 10px;
            margin-top: 10px;
            font-weight: 600;
        }
    
        .permission-icon{
            margin-right: 5px;
        }
    
        .module-card-info{
            margin-right: 50px;
            background-color: white;
            border-radius: 6px;
            padding: 10px;
        }
    }
    .permissions-info-title{
        font-weight: bold;
        margin-bottom: 25px;
        margin-top: 10px;

    }
}

}

.module-info-item{
    font-weight: 400;
    margin-top: 5px;
}



  
    .calendar-icon{
        fill: var( --color-gray-dark);
    }
    .view-eye-icon{
        fill: var( --color-green-soft);
    }
    .edit-pencil-icon{
        fill: var(--color-orange-vivid);
    }
    .trash-icon{
        fill: var(--color-red-soft);
    }
    .add-icon {
        fill: var(--color-blue-soft);
    }
    .assign-icon {
        fill: var(--color-blue-ligth);
    }


    @media (max-width: 768px) {
        .container{

            .permissions-info{
            #permissions-info-container{
                flex-grow: 1;
                overflow-y: auto;
                overflow-x: auto;
                position: relative;
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                gap: 5px;
            }
        }
    }
    }