.calendar_week_component {
    .calendar_container {
        padding: 0.5em;
        width: 100%;
        max-height: 78vh;
        overflow-x: hidden;
        overflow-y: hidden;
        display: flex;
        flex-direction: column;

        .calendar_header {
            display: flex;
            background-color: white;

            //hide scroll
            &::-webkit-scrollbar {
                display: none;
            }

            .day_column:nth-child(2n) {
                background-color: #23374630;
            }

            .header_calendar {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &.cell_hour {
                    background-color: white;
                }

                .add_shift_button,
                .delete_shift_button {
                    display: flex;
                    align-items: center;
                    font-size: 0.7em;
                    background-color: var(--color-blue);
                    padding: 0.3em;
                    padding-right: 1em;
                    border-radius: 10px;
                    color: white;

                    &:hover {
                        cursor: pointer;
                    }

                    &.disabled {
                        background-color: var(--color-gray-dark);
                        color: white;
                    }
                }

                .delete_shift_button {
                    background-color: var(--color-red-soft);
                }
            }

        }

        .calendar_corp {
            display: flex;
            flex-grow: 1;
            overflow-y: auto;
            overflow-x: hidden;

            .day_column {
                background-color: white;
                position: relative;


                &:nth-child(2n) {
                    background-color: #23374630;
                }

                .cells_container {
                    position: relative;

                    .wrapper_for_add_shift {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        z-index: 0;
                        font-size: 1.5em;
                        background-color: #fafafabe;
                        opacity: 1;
                    }
                }
            }



            .dotted_line {
                //dotted with little lines with good space
                height: 2px;
                border-bottom: 2px dashed rgba(255, 0, 0, 0.292);
                position: absolute;
                right: 0;
                z-index: 100;
                left: 0;
            }

            .today_line {
                height: 2px;
                background-color: red;
                position: absolute;
                right: 0;
                left: 0;
                z-index: 100;
            }

            .event_card {
                position: absolute;
                height: 100%;
                min-height: 2em;
                overflow-y: hidden;

                &.isRunning {
                    .card_event_schedule_component {

                        z-index: 302 !important;
                        // border: solid thin var(--color-green-soft);
                        border-radius: 5px;

                        .content {
                            background-color: #fff9e2;

                        }
                    }

                }

                .card_event_schedule_component {
                    .billable_container {
                        display: none !important;
                    }
                }

                &:hover {
                    cursor: pointer;
                    z-index: 300 !important;
                    min-height: none;
                    height: auto;
                    overflow-y: visible;
                }
            }

            .cell {
                border-bottom: solid thin #23374630;
                width: 100%;

                &_clickeable:hover {
                    background-color: var(--color-yellow);
                }

                &:nth-child(2n) {
                    border-bottom: dotted thin #23374630;

                }
            }
        }
    }



    &.editing {
        .adding_button {
            &.selected_event {

                .container_text,
                .icon_container {
                    color: var(--color-yellow);
                }
            }

            &:not(.selected_event) {
                opacity: 0.3;
            }
        }

        .calendar_container {
            .cells_container {
                position: relative;
                z-index: 1;
            }


            .event_card {
                opacity: 0.3;

                &:hover {
                    cursor: pointer;
                    z-index: 10000 !important;
                }

            }
        }
    }
}