.shift_list_page {
    overflow: hidden;
    height: 100%;
    
    .page_content{
        overflow-x: hidden;
        overflow-y: auto;
        height: 100%;
    }
    .shift_row {
        display: flex;
        .column_nane_shift{
            display: flex;
            justify-content: end;
            padding-right: 1em;
        }
        .column_shift {
            background-color: #FBFBFB;

            &:nth-child(2n) {
                background-color: #DFE2E4;

            }
        }

        &.odd {
            .column_shift {
                background-color: #F6F6F6;

                &:nth-child(2n) {
                    background-color: #E4E6E7;
                }
            }
        }

        .calendar_shift{
            position: relative;

            .shift_line_in_page{
                position: absolute;
                top: 2px;
            }
        }
    }

}

.shift_content{
    overflow: auto;
}

.container_shit_calendar{
    padding: 20px 20px;
}


#calendar_header {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: -16.5px; /* Stick to the top of the container */
    z-index: 100; /* Ensure it appears on top of other content */
    background-color: white; /* Ensures the background stays visible while scrolling */
}




