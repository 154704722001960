.experience_container_body {
    width: 100%;
    height: calc(100vh - 70px);
    padding: 0;
    padding: 5px 20px !important;
}

.experience_container {
    margin: 0;
    width: 100%;
    height: 100% !important;
}
.experience_container_02 {
    margin: 0;
    width: 100%;
    height: 100% !important;
}

.aisearch_title_filter{
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 30px;
}

.filter_by_person{
    background-color: white;
    border: 1px solid rgb(194, 194, 194);
    padding: 20px;
    border-radius: 8px;

    p {
        font-weight: 500;
    }
    span{
        font-style: oblique;
        font-weight: 400;
    }
}
.clean_button{
    text-decoration: underline;
    cursor: pointer;
    font-weight: 500;
}
.subtitle_filter{
    margin-top: 10px;
}

.search_section {
    display: flex; 
    width: 100%;
    text-align: center;
    justify-content: end;
    padding: 10px;
}

.search_section_inner{
    display: flex; 
    border: 1px solid rgb(177, 177, 177);
    padding: 2px 4px;
    border-radius: 6px;
    cursor: pointer;
}
.search_section_inner:hover{
    border: 1px solid rgb(43, 43, 43);
}

.ai_score{
    border: 1px solid rgb(24, 24, 24);
    width: fit-content;
    height: fit-content;
    padding: 5px;
    border-radius: 6px;
}
.ai_score_label{
    font-weight: 600;
}

.ai_manual_score{
    border: 1px solid rgb(131, 130, 130);
    border-radius: 6px;
    padding: 3px 5px;
}
.ai_manual_comment{
    border: 1px solid rgb(131, 130, 130);
    border-radius: 6px;
    padding: 5px;
}



.input_moment{
    border-radius: 6px;
    margin-right: 5px;
    border: 1px solid rgb(180, 180, 180);
    padding: 4px 10px;
    width: 100%;
    
}

.update_moment{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 10000 !important;
    /* Flexbox alignment */
    display: flex;
    justify-content: center; /* Horizontally centers the content */
    align-items: center;    /* Vertically centers the content */
}
.update_moment_inside{
    background-color: white;
    width: 50%;
    height: fit-content;
    padding: 20px;
    border-radius: 6px;
    color: black;
}
.update_moment_inside_close{
    width: 100%;
    /* Flexbox alignment */
    display: flex;
    justify-content: flex-end; /* Aligns content to the right */
    align-items: center;       /* Optional: centers content vertically */
}

.update_moment_inside_title{
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    text-align: center;
}

.moment_input_creation{
    margin-bottom: 20px;
    border-radius: 6px;
    border: 1px solid rgb(187, 187, 187);
    height: 35px;
    padding: 2px 10px;
}

.loading_moments{
    height: 100%;
    margin-top: 100px;
}

.moment_add_keywords{
    border: 2px solid rgb(44, 155, 247);
    border-radius: 50%;
    width: 30px;
    fill: rgb(44, 155, 247);
    color: rgb(44, 155, 247);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.input_moment_container{
    display: flex;
    margin-top: 10px;
    justify-content: left; /* Horizontally centers the content */
    align-items: center;    /* Vertically centers the content */
}

.moment_positive_container{
    border: 2px solid rgb(31, 241, 112) !important;
    margin-bottom: 10px;
}
.moment_neutral_container{
    border: 2px solid rgb(161, 161, 161) !important;
    margin-bottom: 10px;
}
.moment_negative_container{
    border: 2px solid rgb(250, 77, 54) !important;
}

.questions_scores_title{
    font-weight: 600;
    margin-top: 20px;
    font-size: 20px;
}
.questions_scores_container{
    justify-content: center;
    border: 1px solid rgb(220, 220, 220);
    display: flex;
    margin-bottom: 20px;
    .score_item{
        margin-right: 10px;
        text-align: center;
        padding: 10px;
        background-color: #f7f5f5;
        border-radius: 6px;
        margin: 10px;
        p{
            font-weight: 600;
        }
    }
}

.moment_filter{
    background-color: white;
    border: 1px solid rgb(180, 180, 180);
    padding: 20px 20px;
    border-radius: 6px;
    display: flex;
    width: 100%;
}
.moment_right_container{
    width: 40%;
}
.moment_left_container{
    width: 60%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.moment_description{
    border: 1px solid rgb(214, 214, 214);
    border-radius: 6px;
    margin-left: 20px;
    padding: 20px;
    width: 80%;
    height: fit-content;
}
.customer_moment_clear{
    text-decoration: underline;
    color: #2c9bf7;
    margin-top: 5px;
    cursor: pointer;
}

.updating-message-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.updating-message-container p {
    font-size: 20px;
    font-weight: bold;
    color: #333;
}
.download_transcription_container{
    display: flex;
    justify-content: left;
    padding: 10px;
    margin-bottom: 20px;
    .download_transcription_container_inner{
        display: flex;
        background-color: #233746;
        color: white;
        padding: 10px 10px;
        border-radius: 6px;
    }
    .download_transcription_button:hover{
        color: white;
    }
}

.checkbox_applied_scoring{
    padding: 5px 0px;
    display: flex;
    margin-top: 10px;
    align-items: center;    /* Vertically centers the content */
    justify-content: left;
}

.not_aplicable_option{
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;    /* Vertically centers the content */
    justify-content: left;
    input{
        margin-right: 5px;
    }
}
.applicable_options_container{
    display: flex;
}
.applicable_options{
    margin-right: 10px;
    display: flex;
    align-items: center;    /* Vertically centers the content */
    justify-content: left;
    input{
        margin-right: 5px;
    }
}

.playing {
    background-color: #ffff00;
    text-decoration: underline;
}  

.word_conversation:hover{
    text-decoration: underline;
}

.anget_filter{
    z-index: 1000;
}
.anget_filter_up{
    z-index: 1100;
}

.anget_filter_down{
    z-index: 900 !important;
}