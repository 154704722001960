.tracking_time_on_hold_component{
    display: flex;
    min-height: 50vh;

    .onhold_right_container{

        width: 50%;
        padding: 10px;

        .onhold_title{
            font-size: 40px;
            font-weight: 800;
            color: #E6C860;
        }

        .onhold_subtitle{
            font-size: 30px;
            font-weight: 400;
        }

        .tracking_card{
            margin-bottom: 20px;
        }
    }
    .onhold_left_container{
        
        width: 50%;
        padding: 10px;
    }
}
