.card-employee-component {

    .card-body {
        background-color: white;
        padding: 1em;
        border-radius: 5px;

        .circle-image-container {
            width: 5em;
            height: 5em;
            border-radius: 100%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .card-body-content{
        display: flex;
    }
}