.button_component {
    display: flex;
    height: fit-content;
    align-items: center;
    border-radius: 5px;
    font-size: 14px;
    border: none;
    appearance: none;
    -webkit-appearance: none;

    &[type=button],
    &[type=reset],
    &[type=submit] {
        appearance: none;
        -webkit-appearance: none;
        padding: 0;
    }




    .icon_container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        background-color: #ffffff24;
        border-radius: 5px 0 0 5px;
        padding: 0.5em;

        .icon {
            width: 1.2em;
            height: 1.2em;
            color: currentColor;
        }
    }

    .text {
        display: flex;
        align-items: center;
        flex-grow: 1;

        .container_text {
            padding: 0.5em 1em 0.5em 0em;
        }

        .color_line {
            width: 5px;
            height: calc(100% - 0.2em);
            border-radius: 0 5px 5px 0;
            margin: 0.1em;
        }
    }

    &.main {
        background-color: var(--color-blue);
        color: white;
    }

    &.danger {
        background-color: var(--color-red-soft);
        color: white;
    }

    &.disabled {
        background-color: var(--color-gray-dark);
        color: white;
    }
}