iframe#webpack-dev-server-client-overlay{display:none!important}
:root {
  --color-blue: #233746;
  --color-yellow: #E6C860;
  --color-yellow-cake: #FFF7DB;
  --color-gray: #D1D1D1;
  --color-gray-light: #EFEFEF;
  --color-gray-dark: #777777;
  --color-red-soft: #ee4c4c;
  --color-green-soft: #36ca36;
  --color-blue-ligth: #757575;
  --color-blue-soft: #4cb7ff;
  --color-orange-vivid: #ffa600;
}

.title_app {
  display: flex;
  font-size: 1.4em;
}

.bg {
  background-size: cover;
  background-repeat: no-repeat;

  &_1 {
    background-image: url(../public/assets/bg/bg_1.png);
  }

  &_2 {
    background-image: url(../public/assets/bg/bg_2.png);
  }

  &_yellow {
    background-color: var(--color-yellow);
  }

  &_gray {
    background-color: var(--color-gray-light);
  }

  &_with_rec_01 {
    position: relative;
    min-height: 100%;

    &::before {
      content: '';
      background-image: url(../public/assets/recs/rec_01.png);
      position: absolute;
      left: 0;
      bottom: 0;
      width: 50vw;
      height: 40vh;
      background-repeat: no-repeat;
    }
  }
}


form {
  .form-group.error {

    input,
    textarea,
    select {
      &::placeholder {
        color: #bf1650;
      }

      color: #bf1650;
      border: solid thin #bf1650;
      background-color: #bf165055;
    }

    aside {
      color: #bf1650;

      &::before {
        display: inline;
        content: "⚠ ";
      }
    }

    label {
      color: #bf1650;
    }
  }

  aside {
    color: #bf1650;
  }

  .password_input_wrapper {
    position: relative;

    .visibility_icon {
      position: absolute;
      top: 50%;
      right: 0.5em;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
}

.hover:hover {
  cursor: pointer;
}

.text {
  &_yellow {
    color: var(--color-yellow);
  }

  &_blue {
    color: var(--color-blue);
  }

  &_bold {
    font-weight: bold;
  }

  &_underline {
    text-decoration: underline;
  }

  &_ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
  }

  &_justify {
    text-align: justify;
  }
}

.btn {
  // svg path:not([fill="none"]) {
  //   // strokeWidth: 2;
  // }

  &.btn_light {
    background-color: var(--color-gray-light);
    // color: black;
    color: var(--color-orange-blue);

    &:hover {
      background-color: var(--color-gray-light);
      color: var(--color-orange-blue);
    }
  }

  &.btn_primary {
    background-color: var(--color-blue);
    color: white;

    &.text_yellow {
      color: var(--color-yellow);
    }

    &:hover {
      background-color: var(--color-blue);
      color: white;
    }
  }
}

body a {
  color: currentColor;
  text-decoration: none;

  &:hover {
    color: var(--color-blue);
  }
}

.chip {
  border-radius: 3em;
  background-color: #EFEFEF88;
  padding: 0.2em 0.7em;
  border: solid thin var(--color-blue);

  &.active {
    background-color: var(--color-yellow-cake);
    color: var(--color-blue);
    border-color: var(--color-yellow);
  }
}

.cirlce_status {
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin-right: 0.5em;

  &.ON.GOING {
    background-color: #FFC107;
  }

  &.COMPLETED {
    background-color: #4CAF50;
  }

  &.CANCELED {
    background-color: #F44336;
  }

  &.PENDING {
    background-color: #9E9E9E;
  }
}

.img {
  &_rounded {
    border-radius: 100%;
    object-fit: cover;
  }
}


.floating_button {
  position: fixed;
  right: 1em;
  bottom: 1em;
  z-index: 100;

  &>.button_component.main {
    color: var(--color-yellow);
    font-weight: bold;
    box-shadow: 0 0 0.5em 0.1em #00000055;
  }
}

.table {
  thead {
    tr {
      &>th {
        border-bottom: solid thin #D1D1D1;
        padding: 1.3em 0.5em 0.5em 1em;
        background-color: #FBFBFB;

        &:nth-child(2n + 1):not(:first-child) {
          background-color: #DFE2E4;
        }
      }
    }
  }

  tbody {
    tr {
      &>td {
        border: none;
        padding: 1.3em 0.5em 0.5em 1em;
        background-color: #FBFBFB;

        &:nth-child(2n + 1):not(:first-child) {
          background-color: #DFE2E4;
        }
      }

      &:nth-child(2n) {
        &>td {
          padding: 1.3em 0.5em 0.5em 1em;
          background-color: #F6F6F6;

          &:nth-child(2n + 1):not(:first-child) {
            background-color: #E4E6E7;
          }
        }
      }
    }
  }
}

table {
  width: 100%;
  border-collapse: collapse;

  th, td {
      padding: 8px;
      text-align: left;
  }

  th:first-child, td:first-child {
      position: sticky;
      left: 0;
      z-index: 1; // Optional: to ensure the sticky column is above other content
  }

  thead th {
  }
}

.table_moments{
  table {
    width: 90% !important;
  
    th, td {
        padding: 8px 8px !important;
    }
  
    th:first-child, td:first-child {
      position: sticky;
      width: 80px !important;
      left: 0;
      background-color: #f9f9f9; // Optional: Background color for visibility
      z-index: 1; // Ensures the sticky column stays above other content
    }
    th:nth-child(3), td:nth-child(3) {
      position: sticky;
      width: 200px !important;
      left: 0;
      background-color: #f9f9f9; // Optional: Background color for visibility
      z-index: 1; // Ensures the sticky column stays above other content text-align: center !important;
      margin-right: 0px !important;
      text-align: center !important;
    }
    th:nth-child(4), td:nth-child(4),
    th:nth-child(5), td:nth-child(5),
    th:nth-child(6), td:nth-child(6),
    th:nth-child(7), td:nth-child(7),
    th:nth-child(8), td:nth-child(8)
    {
      position: sticky;
      width: 150px !important;
      left: 0;
      background-color: #f9f9f9; // Optional: Background color for visibility
      z-index: 1; // Ensures the sticky column stays above other content
      text-align: center !important;
    }

    th:nth-child(9), td:nth-child(9) {
      position: sticky;
      width: 150px !important;
      background-color: #f9f9f9; // Optional: Background color for visibility
      z-index: 1; // Ensures the sticky column stays above other content
      text-align: center !important;
    }

  
  }
}

.table_home{
  .awsui_refresh_14iqq_1w0sy_155{
    top: 0 !important;
    border-top: 1px solid rgb(179, 179, 179);
  }
  .awsui_header-secondary_wih1l_vhdn9_248{
    position: absolute;
    padding-bottom: 0px;
  }
  table {
    width: 90% !important;
    td {
        padding: 12px 8px !important;
    }
  
    th:nth-child(11), td:nth-child(11) {
      position: sticky;
      width: 200px !important;
      background-color: #f9f9f9; // Optional: Background color for visibility
      z-index: 1; // Ensures the sticky column stays above other content
      text-align: left !important;
    }
  }
}
.hide_scroll::-webkit-scrollbar {
  display: none;
}

.w-fit-content {
  width: fit-content;
}

