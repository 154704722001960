.staff_per_shift_page {
    overflow: hidden;
    height: 100%;

    .page_content {
        overflow: auto;
        height: 100%;
    }

    .table {

        td,
        th {
            white-space: nowrap;
            overflow: auto;
            text-overflow: ellipsis;
        }
    }

    .shift_cell {
        width: 350px;
    }

    .shift_box {
        .shift_circle {
            width: 0.3em;
            height: 0.3em;
            border-radius: 100%;
        }
    }
}

#week_content_staff{
    margin-top: -60px;
    width: 100%;
}

#calendar_header_staff{
    width: 100%;
    height: fit-content;
    max-height: fit-content !important;
    background-color: rgba(255, 255, 255, 0);
    position: sticky;
    top: -30px;
    z-index: 10;
}

.viewall{
    margin-top: 0px;
    font-style: oblique;
    font-weight: 300;
    text-decoration: underline;
    cursor: pointer;
}
.viewall:hover{
    color: rgb(119, 119, 119);
}

.viewallusers{
    font-style: oblique;
    font-weight: 300;
    text-decoration: underline;
    cursor: pointer;
}
.viewallusers:hover{
    color: rgb(119, 119, 119);
}

.back_modal_events{
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 10000;
}

.modal_inner{
    position: relative;
    width: 300px;
    height: 50vh;
    background-color: white;
    margin-left: calc(50% - 100px);
    margin-top: 25vh;
    border-radius: 8px;
    padding: 10px;
    text-align: left;
}

.modal_list{
    position: relative;
    display: block;
    overflow: auto;
    max-height: 40vh;
    //border: 1px solid rgb(199, 196, 196);
    padding: 5px;
    border-radius: 6px;
}

.users_modal_list{
    border-bottom: 1px solid rgb(216, 216, 216);
    position: relative;
    display: block;
    overflow: auto;
    max-height: 40vh;
    padding: 5px;
    border-radius: 6px;
}

.modal_list::-webkit-scrollbar {
    width: 8px;
}

.modal_list::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
}

.modal_list::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

.modal_list::-webkit-scrollbar-track {
    background-color: #f0f0f0;
}





.users_modal_list::-webkit-scrollbar {
    width: 8px;
}

.users_modal_list::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
}

.users_modal_list::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

.users_modal_list::-webkit-scrollbar-track {
    background-color: #f0f0f0;
}

