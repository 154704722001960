.card-role-component {
    .card-body {
        background-color: white;
        padding: 1em;
        border-radius: 5px;

        min-height: 450px !important;

        .modules-card-title{
            font-weight: 700;
        }

        .modules-container{
            display: block;

            .modules-subtitle{
                margin-right: 10px;
                font-weight: 300;
            }
            .modules-title{
                font-weight: 400;
                margin-bottom: 10px;
            }
            .calendar-icon{
                fill: var( --color-gray-dark);
            }
            .view-eye-icon{
                fill: var( --color-green-soft);
            }
            .edit-pencil-icon{
                fill: var(--color-orange-vivid);
            }
            .trash-icon{
                fill: var(--color-red-soft);
            }
            .add-icon {
                fill: var(--color-blue-soft);
            }
            .assign-icon {
                fill: var(--color-blue-ligth);
            }
        }

        .circle-image-container {
            width: 5em;
            height: 5em;
            border-radius: 100%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}