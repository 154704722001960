.user-by-role-container{
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 20px;
    padding-bottom: 5px;
    padding-top: 5px;
    .user-by-role{
        display: flex;
        overflow: hidden;
        align-items: center;
    }
    .last-item {
        margin-left: auto;
    }

    .circle-image-container {
        width: 3em;
        height: 3em;
        border-radius: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
}