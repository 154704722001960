.not_results_component {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f7f7f7;
    font-family: Arial, sans-serif;
  
    h3 {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  
    p {
      font-size: 16px;
      text-align: center;
    }
  }
  