.delete-back-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(112, 112, 112, 0.3);
    z-index: 100;
    .delete_modal_component{
        position: absolute;
        width: 400px;
        height: auto;
        z-index: 10;
        background-color: white;
        border-radius: 6px;
        top: calc(50% - 200px);
        left: calc(50% - 200px);
        box-shadow: 0 1px 2px #707070;
        padding: 10px;

        .delete_modal_component_header{
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid rgb(216, 216, 216);
            font-weight: 600;
            padding: 10px;
            .delete_modal_component_header_icon{
                stroke-width: 1px;
                stroke: rgb(87, 87, 87);
                cursor: pointer;
            }
        }

        .delete_modal_component_content{
            padding: 10px;
        }

        .delete_modal_component_content_input{
            width: 90%;
            margin-left: 5%;
            border: 1px solid var(--color-red-soft);
            border-radius: 4px;
            padding: 5px;
            margin-bottom: 10px;
        }

    }
}


.delete_modal_component_footer{
    width: 100%;
    display: flex;
    justify-content: end;
    padding: 10px;
    .delete_modal_component_delete{
        background: var(--color-red-soft);
        color: white;
        border: 1px solid var(--color-red-soft);
        border-radius: 4px;
    }
    .delete_modal_component_cancel{
        background: white;
        color: rgb(87, 87, 87);
        border: 1px solid white;
        border-radius: 4px;
        float: right;
        margin-right: 10px;
    }
}


