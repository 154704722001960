.create-back-container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(112, 112, 112, 0.3);
    z-index: 10;

    .create_modal_component{
        position: absolute;
        width: 500px;
        height: auto;
        z-index: 10;
        background-color: white;
        border-radius: 6px;
        top: calc(50% - 200px);
        left: calc(50% - 250px);
        box-shadow: 0 1px 2px #707070;
        padding: 10px;

        .create_modal_component_header{
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid rgb(216, 216, 216);
            font-weight: 700;
            padding: 10px;
        }
        .create_modal_component_content{
            padding: 10px;

            .create_modal_component_content_titles{
                font-weight: 600;
            }

            .create_modal_component_content_input_name{
                width: 100%;
                height: 40px;
                border: 1px solid rgb(216, 216, 216);
                border-radius: 4px;
                padding: 5px 10px 5px 10px;
                color: rgb(100, 100, 100);
            }
            .create_modal_component_content_input_name:focus {
                border-color:rgb(180, 180, 180);
                outline: none;
            }
            .create_modal_component_content_input_description{
                width: 100%;
                height: 100px; 
                max-height: 600px;
                border: 1px solid rgb(216, 216, 216);
                border-radius: 4px;
                padding: 10px;
                color: rgb(100, 100, 100);
            }
            .create_modal_component_content_input_description:focus {
                border-color:rgb(180, 180, 180);
                outline: none;
            }
        }
    }
}

.create_modal_component_footer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    .create_modal_component_cancel{
        width: calc(50% - 5px);
        border: 1px solid var(--color-blue);
        background-color: white;
        border-radius: 4px;
    }
    .create_modal_component_delete{
        width: calc(50% - 5px);
        background-color: var(--color-blue);
        color: white;
        border-radius: 4px;
    }
}


