.card-role-component {
    flex: 1;
    .card-body {
        background-color: white;
        padding: 1em;
        border-radius: 5px;
        height: 100%;
        .role-title-detail{
            font-weight: 700;
            margin-bottom: 20px;
        }
        .role-description-detail{
            text-align: justify;
        }
    }
}


