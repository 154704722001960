.grid-navigation{
    position: fixed;
    margin: 0;
    width: 100%;
    height: calc(100vh - 200px);
    margin-top: 140px;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;    /* Center vertically */
    overflow-y: auto;
}

.scroll-container {
    position: relative;
    width: 80%;
    max-height: 100%; /* Ensure it does not exceed the container height */
    overflow-y: auto; /* Enable vertical scrolling */
}

.scroll-button {
    background-color: var(--color-blue);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
    user-select: none;
}

.scroll-button:hover {
    background-color: var(--color-blue);
}

.grid-container {
    display: grid;
    gap: 20px;
    overflow-y: auto; /* Enable vertical scrolling */
    height: 20%; /* Set a fixed height for the container */
    max-height: 20%;
    padding: 10px; /* Optional padding */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
    width: 100%;
    margin: 0 auto; /* Center horizontally */
    grid-template-columns: repeat(6, 1fr);

    @media (max-width: 1200px) {
        grid-template-columns: repeat(4, 1fr); /* 4 columns when screen width is <= 1200px */
    }

    @media (max-width: 800px) {
        grid-template-columns: repeat(3, 1fr); /* 3 columns when screen width is <= 800px */
    }

    @media (max-width: 600px) {
        grid-template-columns: repeat(2, 1fr); /* 2 columns when screen width is <= 600px */
    }

    @media (max-width: 400px) {
        grid-template-columns: repeat(1, 1fr); /* 1 column when screen width is <= 400px */
    }
}

.grid-item {
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    height: 150px;
    width: 150px;
    display: flex; /* Enable flexbox */
    flex-direction: column; /* Stack children vertically */
    justify-content: center; /* Vertically center content */
    align-items: center; /* Horizontally center content */
    text-align: center;
}
.grid-item:hover {
    background-color: #e7e7e7;
}
/* Your other styles... */

.home_header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    padding: 16px;
    user-select: none; /* Prevent text selection */
    background-color: var(--color-blue);
}

.logo {
    margin-right: 16px;
    width: 100px;
    height: auto;
}

.title_app {
    display: flex;
    color: white;
}

.text_bold {
    font-weight: bold;
    margin-bottom: 4px;
}

.home_title {
    text-align: center;
    margin: 16px 0;
    font-size: 24px;
    font-weight: bold;
}
.item_title{
    margin-top: 10px;
    font-weight: 500;
}

.modules_modal_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modules_modal {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modules_modal_item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    
    user-select: none; /* Prevent text selection */
}

.modules_modal_item:last-child {
    border-bottom: none;
}

.modules_modal_item:hover {
    background-color: #f3f3f3;
}
.home_signout {
    position: fixed;
    bottom: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.home_signout .icon {
    margin-right: 8px;
}
