.user_edition_container{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.6);

    .user_edition_modal{
        border-radius: 6px;
        background-color: white;
        width: fit-content;
        height: fit-content;
        padding: 20px 0px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .user_edition_item{
            padding: 5px 60px;
            cursor: pointer;
        }
        .user_edition_item:hover{
            padding: 5px 60px;
            cursor: pointer;
            background-color: rgb(224, 224, 224);
        }
    }

    .user_edition_modal.delete{
        padding: 20px;
        
    }
}