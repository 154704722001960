.role-modules-policies-container{
    background-color: white;
    border-radius: 10px;
    padding: 1em;

    .role-edition-module-section{
        padding-bottom: 20px;

        .role-edition-module-title{
            font-weight: 500;
            margin-bottom: 5px;

            .calendar-icon{
                margin-right: 10px;
                margin-top: -2px;
            }
        }

        .role-edition-module-subtitle{
            font-weight: 400;
        }
    
        .role-edition-module-permissions{
            margin-top: 10px;
            display: flex;
    
            .role-edition-module-permissions-item{
                margin-right: 20px;

                .permission-checkbox-container{
                    width: 100px;
                    font-weight: lighter;
                }

                .role-checkbox{
                    margin-right: 5px;
                }
            }
            
        }
    }
}

input[type='checkbox'] {
    accent-color: var(--color-blue);
}


.role-edition-save-button-section{
    width: 100%;
    text-align: right;
    margin-top: 20px;

    .role-edition-save-button{
        background-color: var(--color-blue);
        color: var(--color-yellow);
        border: 1px solid white;
        padding: 5px 15px 6px 10px;
        font-weight: 600;
        border-radius: 6px;

        .role-edition-save-button-icon{
            fill: var(--color-blue);
            background-color: var(--color-yellow);
            border-radius: 50%;
            margin-right: 10px;
        }
    }
}

.create_modal_component_content{
    padding: 10px;

    .create_modal_component_content_titles{
        font-weight: 600;
    }

    .create_modal_component_content_input_name{
        width: 100%;
        height: 40px;
        border: 1px solid rgb(216, 216, 216);
        border-radius: 4px;
        padding: 5px 10px 5px 10px;
        color: rgb(100, 100, 100);
    }
    .create_modal_component_content_input_name:focus {
        border-color:rgb(180, 180, 180);
        outline: none;
    }
    .create_modal_component_content_input_description{
        width: 100%;
        height: 100px; 
        max-height: 600px;
        border: 1px solid rgb(216, 216, 216);
        border-radius: 4px;
        padding: 10px;
        color: rgb(100, 100, 100);
    }
    .create_modal_component_content_input_description:focus {
        border-color:rgb(180, 180, 180);
        outline: none;
    }
}