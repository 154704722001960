.edition-button{
    background-color: var(--color-blue);
    color: white;
    font-weight: 400;
    border-radius: 4px;
    display: flex;
    height: fit-content;
    width: fit-content;
    padding-right: 0px;
    cursor: pointer;
    float: right;

    .edition-button-icon{
        background-color: #81818180;
        text-align: center;
        border-radius: 4px 0px 0px 4px;
        height: 30px;
        width: 30px;
        margin-right: 10px;
    }
    .edition-button-line{
        background-color: #81818180;
        border-radius: 0px 4px 4px 0px;
        height: 30px;
        width: 10px;
        margin-left: 10px;
        float: right;
    }
    .edition-button-name{
        padding-top: 2px;
    }

    .edit-pencil-icon{
        fill: white;
    }
}

.action-button-container{
    float: right;
    cursor: pointer;
}
.modal-button-container{
    position: absolute;
    background-color: white;
    width: fit-content;
    max-width: 200px;
    width: fit-content;
    margin-top: 35px;
    border-radius: 6px;
    box-shadow: 0 1px 2px #707070;
    
    padding: 10px 0px;

    .edit-pencil-icon-modal{
        margin-right: 10px;
    }

    .modal-button-item{
        display: flex;
        font-weight: 400;
        margin-top: 0px;
        padding: 5px 5px;
    }
    .modal-button-item:hover{
        background-color: #e5e5e5;
    }
}
