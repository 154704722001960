.update_password_recovery_page{
    .forget_password_link{
        color: black;
        text-align: center;
        text-decoration: none;

        &.disabled{
            color: #ccc;
        }
    }
}