.auto_complete_with_check_component {
    .item-by-content {
        display: flex;
    }

    .last-item {
        margin-left: auto;
    }

    .input_keyname {
        margin-bottom: 5px;
        font-weight: 600;
    }

    .option_image{
        height: 30px;
        margin-left: 0.5em;
        margin-right: 0.5em;

        &.rounded_image {
            width: 30px;
            object-fit: cover;
            border-radius: 100%;
        }
    }
}