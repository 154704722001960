.social_login_component {
    .o_container {
        position: relative;
        font-size: 24px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        div {
            z-index: 1;
            padding: 0.4em;
            position: relative;
            width: fit-content;
            background-color: white;
        }

        &::before {
            position: absolute;
            content: '';
            top: calc(50% - 1px);
            left: 0;
            width: 100%;
            height: 2px;
            background-color: var(--color-gray-light);
        }
    }
}