.update_password_recovery_page{
    .forget_password_link{
        color: black;
        text-align: center;
        text-decoration: none;

        &.disabled{
            color: #ccc;
        }
    }
    
    .reduced{
        width: 100%;
        .ReactInputVerificationCode__container{
            width: 100%;
        }
        .ReactInputVerificationCode__item{
            transform: scale(0.8);
        }
        }
}