.data_loader_spinner_component {
    position: fixed;
    z-index: 1000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2em;

    .loader_spinner {
        max-width: 300px;
        width: 100%;

        .progress {
            background-color: var(--color-gray-light);
            .progress-bar {
                background-color: var(--color-blue);
                background: linear-gradient(120deg, var(--color-blue) 25%, #44647c 65%, var(--color-blue) 80%);
                animation: animatedBackground 2s linear infinite;
                background-size: 200% 200%; // This line is added to ensure the gradient is larger than the container
              
                //animated gradient color
                @keyframes animatedBackground {
                    0% {
                        background-position: 120% 50%;
                    }
              
                    99% {
                        background-position: -120% 50%;
                    }
              
                    100% {
                        background-position: 120% 50%;
                    }
                }
              }
        }
    }
}