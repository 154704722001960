.shift_as_line_component{
    display: flex;
    color: white;
    border-radius: 5px;
    overflow: hidden;

    .shift_hour_container{
        display: flex;

        .shift_filled{
            background-color: var(--color-blue);

            &:last-of-type{
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }

        .shift_title{
            text-overflow: ellipsis;
            overflow: hidden;
        }
        

        .shift_hour{
            display: flex;
            justify-content: end;
            align-items: center;
            padding-right: 0.5em;
        }

    }
}