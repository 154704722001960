.tracking_card_table_event_compare_component {
    min-height: 100%;

    &>.card {
        min-height: 100%;
    }

    .done_fill {
        background-color: var(--color-blue);
    }

    .pending_fill {
        background-color: var(--color-yellow);
    }

    .header {
        .circle_indicator {
            width: 1em;
            height: 1em;
            border-radius: 100%;
        }
    }

    .text_small {
        font-size: 0.9em;
    }

    .line_prg {
        border-radius: 3px;
        height: 0.6em;
    }

    .line_progress {
        position: relative;
        background-color: var(--color-gray);
        width: 100%;

        .line_planned {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background-color: var(--color-yellow);
            z-index: 0;
        }

        .line_real {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background-color: var(--color-blue);
            z-index: 1;
        }


        &.truncated_lines {
            .line_planned {
                z-index: 2;
            }
        }
    }
}