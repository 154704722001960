.filter_side_bar_component.open {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;

    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #0000006c;
        animation: fade-in 0.4s;
    }

    .sidebar {
        background-color: white;
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        max-width: 340px;
        height: 100%;
        display: flex;
        flex-direction: column;

        .header {
            padding: 1.3em;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .title {
                font-size: 1.3em;
                font-weight: bold;
                flex-grow: 1;
            }
        }

        .content {
            padding: 1.3em;
            flex-grow: 1;
            overflow-y: auto;
            padding-bottom: 1.3em;

            .filter_option {
                margin: 1em 0;
            }
        }

        .footer {
            padding: 1.3em;
        }
    }
}

.filter_side_bar_component {
    display: none;
    animation: fade-out 0.03s;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}