.autocomplete_component {
    .item-by-content {
        display: flex;
    }

    .last-item {
        margin-left: auto;
    }

    .input_keyname {
        margin-bottom: 5px;
        font-weight: 600;
    }

    .option_image{
        width: 30px;
        height: 30px;
        margin-left: 0.5em;
        margin-right: 0.5em;
        object-fit: cover;
        border-radius: 5px;
        &.rounded_image {
            border-radius: 100%;
        }
    }
}