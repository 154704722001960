.tracking_time_component {
    position: relative;

    .time_container {
        &.running {
            color: var(--color-green-soft);
        }

        &.paused {
            color: var(--color-yellow-soft);
        }

        &.stopped,
        &.overtimed {
            color: var(--color-red-soft);
        }

        &.overtimed {
            animation: color_alert 1s ease-in-out infinite;

            @keyframes color_alert {
                0% {
                    color: var(--color-red-soft);
                }

                50% {
                    color: red;
                }

                100% {
                    color: var(--color-red-soft);
                }
            }
        }
    }

    .card_time_tracking {
        position: absolute;
        right: 0;
        top: 200%;
        width: 300px;

        &>.position-fixed {
            width: 300px;
            padding: 0.5em;
            border-radius: 10px;
            background-color: #F4F4F4;
            z-index: 1000;

            .card_event_schedule_component {
                .billable_container {
                    display: none !important;
                }
            }
        }
    }
}