.col {
    word-wrap: break-word;
    max-width: 750px;
}

.col-header-wrapper {
    word-wrap: break-word;
    max-width: 10ch;
    display: flex;
}

table {
    width: 100%;
    border-collapse: collapse;
}

.item_filtered{
    display: flex;
    width: fit-content;
    padding: 5px 10px;
    border-radius: 6px;
    margin: 5px 0px;
    border: 1px solid black;
    align-items: center; /* Vertical alignment */
    justify-content: center; /* Optional: Horizontal alignment */
}
.item_filtered_close{
    margin-left: 8px;
    cursor: pointer;
}