#content{
    height: auto;
    flex-grow: 1;

    &>.container{
        height: 100%;
    }
}

.searcher_component{
    height: 100%;

    &>.container{
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        
        #scrollable_container{
            flex-grow: 1;
            // max-height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            position: relative;

            .none_item {
                width: 1px;
                height: 101%;
                position: absolute;
            }

            .infinite-scroll-component {
                // height: 100.10%;
                overflow-x: hidden !important;
            }
        }
    }
}