.back_container_week_calendar{}

.calendar_week_form_component {
    .month_container{
        min-width: 9em;
    }

    .react_calendar_container {
        position: absolute;
        left: 0;
        top: 2.5em;
        width: 300px;
        z-index: 10;


        .react-calendar {
            border: none;
            box-shadow: 0px 0px 2px 2px #efefef;

            .react-calendar__tile--active,
            .react-calendar__tile--hasActive {
                background-color: var(--color-blue);
                color: var(--color-yellow);
            }

            .react-calendar__tile--active:enabled:hover,
            .react-calendar__tile--active:enabled:focus {
                background-color: var(--color-blue);
                color: var(--color-yellow);
            }
        }
    }
}