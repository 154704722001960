.card_tracking_interaction_component {
    font-size: 14px;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    min-height: 80px;
    
    .content {
        border-radius: 5px;
        display: flex;
        padding: 0.5em 0.5em 0.5em 2em;
        position: relative;
        background-color: #ffffff;
        height: 100%;


        .line_event {
            width: 10px;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 5px 0 0 5px;
        }
    }
}