    /* width */
    ::-webkit-scrollbar {
        width: 0px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
        border-radius: 0px;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #797979; 
        border-radius: 10px;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #797979; 
    }

.roles-edition{
    display: flex;
    height: 100%;

    .right_role_info_container{
        display: flex;
        flex-direction: column;
    }

    .users-by-roles-container{
        background-color: white;
        margin-left: 20px;
        padding: 20px;
        height: auto;
        min-height: 550px;
        border-radius: 6px;

        .users-by-role{
            height: fit-content;
            max-height: 25vh;
            overflow: scroll;
        }
        .users-by-role-expanded{
            height: auto;
            overflow: scroll;
            max-height: 400px;
        }
        .users-add-by-role{
            height: fit-content;
            max-height: 25vh;
            overflow: scroll;
        }
        .modal-listing-searching-container{
            position: fixed;
            width: 320px;
            max-height: 300px;
            margin-left: -10px;
            margin-top: 10px;
            display: block;
            background-color: #e9e9e9;
            padding: 10px 20px 0 20px;
            z-index: 10;

        }
        .modal-listing-searching{
            max-height: 200px;
            overflow: scroll;
        }
        .clear-modal-button{
            width: 50px;
            padding-bottom: 2px;
            border: 1px solid #afafaf;
            text-align: center;
            margin-left: calc(100% - 50px);
            border-radius: 5px;
            cursor: pointer;
        }
    }

    /* width */
::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #797979; 
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #797979; 
}
    
}

.no_data_retrieved{
    width: 100%;
    margin-top: 100px;
    text-align: center;
    color: rgb(243, 107, 107);
}