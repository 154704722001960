.modal_component {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1500;

    .back_modal {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .modal_content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        max-width: 100%;

        &.sm {
            width: 300px;
        }

        &.md {
            width: 500px;
        }

        &.lg {
            width: 800px;
        }

        & > .modal_header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            & > .modal_title {
                font-size: 18px;
                font-weight: bold;
            }

            & > .modal_close {
                cursor: pointer;
                font-size: 20px;
            }
        }

    }
}