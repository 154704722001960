.data_loader {
    .dragging_overlay {
        position: fixed;
        margin: 0;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 10;
        background-color: rgba(255, 255, 255, 0.7);
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;


        p {
            text-align: center;
            font-size: 30px;
            font-weight: bold;
            color: rgb(51, 51, 51);
        }

        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
        }

    }

    .body_content_loader {
        .input_select_excel {
            position: relative;
            &:hover {
                cursor: pointer;
            }

            *:hover {
                cursor: pointer;
            }

            input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;

            }
        }
    }
}

.header_content_loader {
    padding: 20px 20px 20px 20px;
}

.title_content_loader {
    font-weight: bold;
}

.body_content_loader {
    padding-left: 20px;
}


.table_container {
    overflow-x: auto;
}

.file_loader_trigger {
    background-color: blue;
    border-radius: 4px;
    text-align: center;
    padding: 5px 10px;
    color: yellow;
}

table {
    width: 90%;
    border-collapse: collapse;
    margin: 20px 20px 20px 5%;
    text-align: center;
}

th,
td {
    border: 1px solid rgba(221, 221, 221, 0.4);
    padding: 8px;
}

th:nth-child(odd),
td:nth-child(odd) {
    background-color: rgba(240, 240, 240, 0.8);
    /* Light gray background for odd columns */
}

th:nth-child(even),
td:nth-child(even) {
    background-color: #fff;
    /* White background for even columns */
}

.header_table_week_day {
    font-weight: 400;
}