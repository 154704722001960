.LayoutComponent {
    display: flex;

    @media (max-width: 768px) {
        &.is_open::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100%;
            background-color: #00000033;
        }
    }

    &>.side_menu {
        background-color: var(--color-blue);
        color: white;
        height: 100vh;
        overflow-y: auto;
        background-repeat: no-repeat;
        background-size: contain;

        .home_link{
            font-weight: 500;
            margin-top: 30px;
            margin-left: -2px;
            cursor: pointer;

            span{
                margin-left: 3px;
            }
        }

        @media (max-width: 768px) {
            position: absolute;
        }

        &.open {
            padding: 1.5em;
            animation-name: openSideMenu;
            width: 300px;
            animation-duration: 0.5s;

            //on mobile
            @media (max-width: 768px) {
                height: calc(100vh - 4em);
                position: fixed;
                top: 4em;
                left: 0;
                z-index: 1;
            }
        }

        &.close {
            animation-name: closeSideMenu;
            animation-duration: 0.5s;
            width: 0;
        }

        @keyframes openSideMenu {
            from {
                width: 0%;
                overflow: hidden;
                opacity: 0;
            }

            to {
                overflow: hidden;
                opacity: 1;
                width: 300px;
            }
        }

        @keyframes closeSideMenu {
            from {
                overflow: hidden;
                opacity: 1;
                width: 300px;
            }

            to {
                overflow: hidden;
                width: 0%;
                opacity: 0;
            }
        }

        &>.header_side {
            display: flex;
            align-items: center;
            width: 100%;

            img {
                width: 30%;
                margin-right: 1em;
                border-radius: 21px;
                border: solid thin #707070;
            }

            .text {
                display: flex;
                flex-direction: column;
            }
        }

        .module {
            display: flex;
            flex-direction: column;

            .page_link {
                margin-top: 0.5em;
                text-decoration: none;
                color: white;
                width: 100%;
                padding-left: calc(24px + 0.5em);
            }

            .active_link {
                background-color: #FFFFFF33;
                padding: 1em;
                border-radius: 20px;
            }

            .header_module {
                text-decoration: none;
                color: white;
                padding: 0.5em 0 0 0;
                margin-top: 1em;
                display: flex;
                align-items: center;
                font-weight: bold;

                &.active_link {
                    padding: 1em;
                }


                span {
                    margin-left: 0.5em;
                }
            }
        }
    }

    &>.right_side {
        height: 100vh;
        flex-grow: 1;
        overflow: hidden;
        width: 100%;
        display: flex;
        flex-direction: column;



        &>.header {
            background-color: white;
            padding: 1em;
            height: 4em;
            align-items: center;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            z-index: 1;
            display: flex;
            justify-content: space-between;

            .image_container{
                width: 2.5em;
                height: 2.5em;
                margin-left: 0.5em;
                border-radius: 100%;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-self: center;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        &>.content {
            &>* {
                padding: 3em;

                @media screen and (max-width: 768px) {
                    padding: 1em;
                }
            }

            .page_content {
                background-color: #ffffffBB;
                padding: 1em 0 3em 0;
                border-radius: 5px;
            }

            overflow-y: auto;
            flex-grow: 1;
            background-color: var(--color-gray-light);
        }

    }
}

.back_loading_bulk{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
}