/* UserManagementStyles.scss */
.container {
    padding: 0px 20px 20px 20px;
}
.update_title{
    padding: 0;
    font-weight: 500;
    font-size: 30px;
    width: 100%;
    text-align: center;
}
.container_update{
    padding: 0px 20px 20px 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px; // Adjust the gap between columns and rows as needed
}


.bulk_user_import{
    display: flex;
    justify-content: end;
    background-color: #e4e4e4;
    padding: 10px 10px 10px 0px;
}

.title_information_user_container{
    font-weight: 500;
    font-size: 20px;
    margin-top: 20px;
}
.container_update.top{
    background-color: #e4e4e4;
    margin-bottom: 20px;
}
.container_update.bottom{
    background-color: #e4e4e4;
    margin-top: 10px;
}

.dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
}

.dropdown-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 10px;
    background-color: white;
}

.dropdown_user_menu_dropdown {
    position: absolute;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
    padding: 12px;
    z-index: 10;
    width: auto;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    margin-top: 10px;
}
.dropdown_user_menu {
    position: absolute;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
    padding: 12px;
    z-index: 10;
    width: auto;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    margin-top: 110px;
}

.dropdown_user_item {
    padding: 8px;
    cursor: pointer;
    width: 100%;
    text-align: left;
}

.dropdown_user_item:last-child {
    border-bottom: none;
}

.dropdown_user_item:hover {
    background-color: #e7e7e7;
}


.selected_users {
    position: relative;
    padding: 10px 0px;
    overflow-y: scroll;
}

.selected_user_list {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0px;
}

.user_selected_item {
    background-color: white;
    margin: 10px;
    width: fit-content;
    min-width: 350px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;

    .user_remove_list_container{
        width: 100%;
        text-align: right;

        .user_remove_list{
            width: 20px;
            height: 20px;
            float: right;
            border-radius: 50%;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }
    
}

.user_list_title{
    margin-top: 20px;
    font-weight: 500;
    margin-bottom: 0px;
}

.item_option_selected{
    border: 1px solid black;
    padding: 4px;
    border-radius: 4px;
    margin: 2px;
    width: fit-content;
}

.delete_modal_buttons{
    width: 80%;
    margin-left: 10%;
    display: flex;
    justify-content: space-around;
}

.drop_loader_view{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    background-color: rgba(228, 228, 228, 0.5);
    text-align: center;
    font-size: 40px;
    color: black;
    font-weight: 500;
    /* Flexbox for vertical alignment */
    display: flex;
    justify-content: center; /* Horizontal alignment */
    align-items: center; /* Vertical alignment */
}