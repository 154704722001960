.circular_indicator_component {
    width: 100%;
    display: flex;
    justify-content: center;
    
    .progress_indicator{
    padding: 0.7em;
    border-radius: 100%;

    .circle {
        height: 100%;
        width: 100%;
        background-color: white;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
}